import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        {/* Creator Information */}
        <p className="creator-info">Created by Web3at50</p>

        {/* Donation Support Message */}
        <p className="donation-message">
          Support me with a donation to <strong>6529stats.eth</strong>
        </p>

        {/* Social Links */}
        <div className="social-links">
          <a
            href="https://x.com/web3_50"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            X: Web3at50
          </a>
          <a
            href="https://seize.io/web3at50"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            Seize: Web3at50
          </a>
          {/* Email Address */}
          <a href="mailto:admin@6529stats.xyz" className="email-link">
            admin@6529stats.xyz
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
