// SalesBotAllTimeComponent.js

import React, { useState, useEffect, useMemo } from 'react';
import { fetchSalesBotAllTimeData } from './apiService';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
import { ArrowUpDown } from 'lucide-react';
import './SalesBotAllTimeComponent.css';

// Etherscan Icon Component
const EtherscanIcon = () => (
  <svg width="20" height="20" viewBox="0 0 123 123" xmlns="http://www.w3.org/2000/svg">
    {/* SVG Path */}
    <path
      d="M25.79 58.4149C25.7901 57.7357 25.9244 57.0633 26.1851 56.4361C26.4458 55.809 26.8278 55.2396 27.3092 54.7605C27.7907 54.2814 28.3619 53.9021 28.9903 53.6444C29.6187 53.3867 30.2918 53.2557 30.971 53.2589L39.561 53.2869C40.9305 53.2869 42.244 53.831 43.2124 54.7994C44.1809 55.7678 44.725 57.0813 44.725 58.4509V90.9309C45.692 90.6439 46.934 90.3379 48.293 90.0179C49.237 89.7962 50.0783 89.262 50.6805 88.5019C51.2826 87.7418 51.6102 86.8006 51.61 85.8309V45.5409C51.61 44.1712 52.154 42.8576 53.1224 41.889C54.0908 40.9204 55.4043 40.3762 56.774 40.3759H65.381C66.7506 40.3762 68.0641 40.9204 69.0325 41.889C70.0009 42.8576 70.545 44.1712 70.545 45.5409V82.9339C70.545 82.9339 72.7 82.0619 74.799 81.1759C75.5787 80.8462 76.2441 80.2941 76.7122 79.5886C77.1803 78.8832 77.4302 78.0555 77.431 77.2089V32.6309C77.431 31.2615 77.9749 29.9481 78.9431 28.9797C79.9113 28.0113 81.2245 27.4672 82.5939 27.4669H91.201C92.5706 27.4669 93.884 28.0109 94.8525 28.9794C95.8209 29.9478 96.365 31.2613 96.365 32.6309V69.3399C103.827 63.9319 111.389 57.4279 117.39 49.6069C118.261 48.4717 118.837 47.1386 119.067 45.7267C119.297 44.3148 119.174 42.8678 118.709 41.5149C115.931 33.5227 111.516 26.1983 105.745 20.0105C99.974 13.8228 92.9749 8.90785 85.1955 5.58032C77.4161 2.2528 69.0277 0.585938 60.5671 0.686416C52.1065 0.786893 43.7601 2.6525 36.062 6.16383C28.3638 9.67517 21.4834 14.7549 15.8611 21.078C10.2388 27.401 5.99842 34.8282 3.41131 42.8842C0.824207 50.9401 -0.0526487 59.4474 0.836851 67.8617C1.72635 76.276 4.36263 84.4119 8.57696 91.7489C9.31111 93.0145 10.3912 94.0444 11.6903 94.7175C12.9894 95.3906 14.4536 95.679 15.911 95.5489C17.539 95.4059 19.566 95.2029 21.976 94.9199C23.0251 94.8008 23.9937 94.2999 24.6972 93.5126C25.4008 92.7253 25.7901 91.7067 25.791 90.6509L25.79 58.4149Z"
      fill="#21325B"
    />
    <path
      d="M25.6021 110.51C34.6744 117.11 45.3959 121.072 56.5802 121.957C67.7646 122.841 78.9757 120.615 88.9731 115.523C98.9705 110.431 107.364 102.673 113.226 93.1068C119.087 83.5405 122.188 72.539 122.185 61.3197C122.185 59.9197 122.12 58.5347 122.027 57.1577C99.808 90.2957 58.7831 105.788 25.604 110.505"
      fill="#979695"
    />
  </svg>
);

// Opensea Icon Component
const OpenseaIcon = () => (
  <svg width="20" height="20" viewBox="0 0 360 360" xmlns="http://www.w3.org/2000/svg">
    <circle cx="180" cy="180" r="180" fill="#2081E2"/>
    <path fill="#fff" d="M252.072 212.292C245.826 220.662 232.686 234.558 225.378 234.558H191.412V212.274H218.466C222.336 212.274 226.026 210.708 228.69 207.954C242.586 193.554 250.614 176.418 250.614 158.04C250.614 126.684 227.178 98.964 191.394 82.26V67.284C191.394 60.84 186.174 55.62 179.73 55.62C173.286 55.62 168.066 60.84 168.066 67.284V73.494C158.04 70.56 147.42 68.328 136.332 67.05C154.692 86.994 165.906 113.67 165.906 142.92C165.906 169.146 156.942 193.23 141.876 212.31H168.066V234.63H129.726C124.542 234.63 120.33 230.436 120.33 225.234V215.478C120.33 213.768 118.944 212.364 117.216 212.364H66.672C65.682 212.364 64.836 213.174 64.836 214.164C64.8 254.088 96.39 284.058 134.172 284.058H240.822C266.382 284.058 277.812 251.298 292.788 230.454C298.602 222.39 312.552 215.91 316.782 214.11C317.556 213.786 318.006 213.066 318.006 212.22V199.26C318.006 197.946 316.71 196.956 315.432 197.316C315.432 197.316 253.782 211.482 253.062 211.68C252.342 211.896 252.072 212.31 252.072 212.31V212.292Z"/>
  </svg>
);

// Seize Icon Component
const SeizeIcon = () => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="#000000" />
    <text x="50%" y="50%" fill="#ffffff" dominantBaseline="middle" fontFamily="Arial, sans-serif" fontSize="10" textAnchor="middle">Seize</text>
  </svg>
);

// Utility function to capitalize first letter
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function SalesBotAllTimeComponent() {
  // State variables
  const [salesData, setSalesData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'createdat', direction: 'desc' });
  const [selectedSaleType, setSelectedSaleType] = useState('All');
  const [selectedMemeName, setSelectedMemeName] = useState('All');
  const [selectedSZN, setSelectedSZN] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 25;
  const [totalRecords, setTotalRecords] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // List of meme names
  const memeNames = [
    'Freedom to Transact',
    'Brain',
    'Open Metaverse / OM',
    'WAGMI',
    'Survive',
    'GM',
    'Seize the Memes of Production',
    "Don't Let the Institutions Steal Your JPGs",
    'Use a Hardware Wallet',
    'Freestyle',
    'Not Your Keys, Not Your Coins',
    'Digital Rights Charter',
    'BUIDL',
    'TAP',
    'Summer.jpg',
    // Add other meme names here
  ];

  // Fetch data from backend
  const fetchData = async () => {
    try {
      const response = await fetchSalesBotAllTimeData({
        page: currentPage + 1,
        pageSize: itemsPerPage,
        saleType: selectedSaleType !== 'All' ? selectedSaleType : undefined,
        memeName: selectedMemeName !== 'All' ? selectedMemeName : undefined,
        szn: selectedSZN !== 'All' ? selectedSZN : undefined,
        searchTerm: searchTerm !== '' ? searchTerm : undefined,
        startDate: startDate !== '' ? startDate : undefined,
        endDate: endDate !== '' ? endDate : undefined,
        sortKey: sortConfig.key,
        sortDirection: sortConfig.direction,
      });
      setSalesData(response.data);
      setTotalRecords(response.totalCount);
    } catch (error) {
      console.error('Error fetching sales bot data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    selectedSaleType,
    selectedMemeName,
    selectedSZN,
    searchTerm,
    sortConfig,
  ]);

  // Implement sorting
  const sortTable = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setCurrentPage(0); // Reset to first page when sorting changes
  };

  // Handle page change
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  // Prepare data for CSV download
  const csvData = useMemo(() => {
    const headers = [
      { label: "Date", key: "createdat" },
      { label: "Name", key: "name" },
      { label: "Sale Type", key: "orderside" },
      { label: "Amount (ETH)", key: "decimal" },
      { label: "Amount (USD)", key: "usd" },
      { label: "Meme Name", key: "memename" },
      { label: "SZN", key: "typeseason" },
      { label: "Seller", key: "from_address" },
      { label: "Buyer", key: "to_address" },
      { label: "Tx Hash", key: "txhash" },
    ];

    const data = salesData.map(item => ({
      ...item,
      orderside: capitalizeFirstLetter(item.orderside),
      decimal: parseFloat(item.decimal).toFixed(5),
      usd: parseFloat(item.usd).toFixed(2),
    }));

    return {
      headers,
      data,
    };
  }, [salesData]);

  // Handle Search button click
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(0);
    fetchData();
  };

  // Handle Clear Search button click
  const handleClearSearch = () => {
    setStartDate('');
    setEndDate('');
    setSelectedMemeName('All');
    setSelectedSaleType('All');
    setSelectedSZN('All');
    setSearchTerm('');
    setCurrentPage(0);
    fetchData();
  };

  return (
    <div className="sales-bot-all-time">
      {/* Updated Title */}
      <h2 className="title">6529 Memes Sales History</h2>

      {/* Form */}
      <form className="filters-form" onSubmit={handleSearch}>
        {/* First row of filters */}
        <div className="filters-row">
          <div className="filter-item">
            <label htmlFor="sale-type-filter">Sale Type</label>
            <select
              id="sale-type-filter"
              value={selectedSaleType}
              onChange={(e) => {
                setSelectedSaleType(e.target.value);
              }}
            >
              <option value="All">All</option>
              <option value="ask">Ask</option>
              <option value="bid">Bid</option>
            </select>
          </div>
          <div className="filter-item">
            <label htmlFor="meme-name-filter">Meme Name</label>
            <select
              id="meme-name-filter"
              value={selectedMemeName}
              onChange={(e) => {
                setSelectedMemeName(e.target.value);
              }}
            >
              <option value="All">All</option>
              {memeNames.map((name, index) => (
                <option key={index} value={name}>{name}</option>
              ))}
            </select>
          </div>
          <div className="filter-item">
            <label htmlFor="szn-filter">SZN</label>
            <select
              id="szn-filter"
              value={selectedSZN}
              onChange={(e) => {
                setSelectedSZN(e.target.value);
              }}
            >
              <option value="All">All</option>
              {[...Array(10).keys()].slice(1).map((szn) => (
                <option key={szn} value={szn}>{szn}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Second row of filters */}
        <div className="filters-row">
          <div className="filter-item">
            <label htmlFor="start-date-filter">Start Date</label>
            <input
              type="date"
              id="start-date-filter"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </div>
          <div className="filter-item">
            <label htmlFor="end-date-filter">End Date</label>
            <input
              type="date"
              id="end-date-filter"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </div>
          <div className="filter-item">
            <label htmlFor="search-input">Search Name</label>
            <input
              type="text"
              id="search-input"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setShowSuggestions(true);
                setCurrentPage(0);
              }}
              onFocus={() => setShowSuggestions(true)}
              onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
              placeholder="Enter name to search"
            />
            {showSuggestions && searchTerm.length >= 2 && (
              <ul className="suggestions-list">
                {salesData
                  .map(item => item.name)
                  .filter((name, index, self) => name.toLowerCase().includes(searchTerm.toLowerCase()) && self.indexOf(name) === index)
                  .slice(0, 5)
                  .map((suggestion, index) => (
                    <li
                      key={index}
                      onMouseDown={() => {
                        setSearchTerm(suggestion);
                        setShowSuggestions(false);
                        setCurrentPage(0);
                      }}
                    >
                      {suggestion}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>

        {/* Buttons and record count */}
        <div className="action-buttons-row">
          <div className="buttons-group">
            <button type="submit">Search</button>
            <button type="button" onClick={handleClearSearch}>Clear Search</button>
          </div>
          <div className="records-and-download">
            <span><strong>Number of Records:</strong> {totalRecords}</span>
            <CSVLink
              data={csvData.data}
              headers={csvData.headers}
              filename={`sales_bot_all_time.csv`}
              className="btn btn-primary"
            >
              Download CSV
            </CSVLink>
          </div>
        </div>
      </form>

      {/* Table */}
      <table>
        <thead>
          <tr>
            {/* Sortable Columns */}
            <th onClick={() => sortTable('createdat')}>
              <div className="header-content">
                Date
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'createdat' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            {/* Non-Sortable Columns */}
            <th>Image</th>
            <th>Name</th>
            {/* Sortable Columns */}
            <th onClick={() => sortTable('orderside')}>
              <div className="header-content">
                Sale Type
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'orderside' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('decimal')}>
              <div className="header-content">
                Amount (ETH)
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'decimal' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('usd')}>
              <div className="header-content">
                Amount (USD)
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'usd' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('memename')}>
              <div className="header-content">
                Meme Name
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'memename' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('typeseason')}>
              <div className="header-content">
                SZN
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'typeseason' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            {/* Non-Sortable Columns */}
            <th>Seller</th>
            <th>Buyer</th>
            <th>Tx</th>
            <th>Opensea</th>
            <th>Seize</th>
          </tr>
        </thead>
        <tbody>
          {salesData.map((item, index) => (
            <tr key={index}>
              <td>{formatDate(item.createdat)}</td>
              <td>
                {/* Removed the condition to omit tokenid 279 */}
                <img
                  src={item.image}
                  alt={item.name}
                  className="item-image"
                />
              </td>
              <td>{item.name}</td>
              <td>{capitalizeFirstLetter(item.orderside)}</td>
              <td>{parseFloat(item.decimal).toFixed(5)}</td>
              <td>${parseFloat(item.usd).toFixed(2)}</td>
              <td>{item.memename}</td>
              <td>{item.typeseason}</td>
              <td>
                <a
                  href={`https://etherscan.io/address/${item.from_address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.from_address.slice(0, 6)}...{item.from_address.slice(-4)}
                </a>
              </td>
              <td>
                <a
                  href={`https://etherscan.io/address/${item.to_address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.to_address.slice(0, 6)}...{item.to_address.slice(-4)}
                </a>
              </td>
              <td>
                <a
                  href={`https://etherscan.io/tx/${item.txhash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <EtherscanIcon />
                </a>
              </td>
              <td>
                <a
                  href={`https://opensea.io/assets/ethereum/${item.contract}/${item.tokenid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OpenseaIcon />
                </a>
              </td>
              <td>
                <a
                  href={`https://seize.io/the-memes/${item.tokenid}?focus=live`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SeizeIcon />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          breakLabel={'...'}
          pageCount={Math.ceil(totalRecords / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>
    </div>
  );
}

export default SalesBotAllTimeComponent;
