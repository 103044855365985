// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import PricesComponent from './PricesComponent';
import TdhStatsComponent from './TdhStatsComponent';
import TdhTableComponent from './TdhTableComponent';
import PricesHistoryComponent from './PricesHistoryComponent';
import MyPortfolioComponent from './MyPortfolioComponent';
import SalesSummaryComponent from './SalesSummaryComponent';
// import SalesBot30DaysComponent from './SalesBot30DaysComponent'; // Removed
import SalesBotAllTimeComponent from './SalesBotAllTimeComponent'; // Existing
import SalesAnalyticsComponent from './SalesAnalyticsComponent'; // Import the new component
import FooterComponent from './FooterComponent';

function App() {
    return (
        <Router>
            <div className="App">
                <div className="content-wrapper">
                    <nav>
                        <ul className="menu">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="dropdown">
                                <span>TDH/PPTDH</span>
                                <ul>
                                    <li>
                                        <Link to="/tdh-table">Data Table</Link>
                                    </li>
                                    <li>
                                        <Link to="/tdh-stats">Historical Stats</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/prices-history">Prices History</Link>
                            </li>
                            <li className="dropdown">
                                <span>Sales</span>
                                <ul>
                                    <li>
                                        <Link to="/sales-summary">Sales Summary</Link>
                                    </li>
                                    <li>
                                        <Link to="/sales-analytics">Sales Analytics</Link> {/* New Link */}
                                    </li>
                                    <li>
                                        <Link to="/sales-all-time">Sales History</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/portfolio">My Portfolio</Link>
                            </li>
                        </ul>
                    </nav>

                    <main>
                        <Routes>
                            <Route path="/" element={<PricesComponent />} />
                            <Route path="/tdh-stats" element={<TdhStatsComponent />} />
                            <Route path="/tdh-table" element={<TdhTableComponent />} />
                            <Route path="/prices-history" element={<PricesHistoryComponent />} />
                            <Route path="/sales-summary" element={<SalesSummaryComponent />} />
                            <Route path="/sales-analytics" element={<SalesAnalyticsComponent />} /> {/* New Route */}
                            <Route path="/sales-all-time" element={<SalesBotAllTimeComponent />} />
                            <Route path="/portfolio" element={<MyPortfolioComponent />} />
                        </Routes>
                    </main>
                </div>
                <FooterComponent />
            </div>
        </Router>
    );
}

export default App;
