import React, { useEffect, useState, useMemo } from 'react';
import { fetchPrices, fetchSznPrices } from './apiService';
import './App.css';
import { ArrowUpDown } from 'lucide-react';
import ReactPaginate from 'react-paginate';

const PricesComponent = () => {
    // State variables
    const [prices, setPrices] = useState([]);
    const [sznPrices, setSznPrices] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'ID', direction: 'ascending' });
    const [latestTimestamp, setLatestTimestamp] = useState('N/A');
    const [selectedSZN, setSelectedSZN] = useState('All');
    const [selectedMemeName, setSelectedMemeName] = useState('All');
    const [selectedSupply, setSelectedSupply] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const itemsPerPage = 25; // Adjust as needed

    useEffect(() => {
        const getPrices = async () => {
            try {
                const response = await fetchPrices({
                    page: currentPage + 1,
                    pageSize: itemsPerPage,
                    sortKey: sortConfig.key,
                    sortDirection: sortConfig.direction === 'ascending' ? 'asc' : 'desc',
                    szn: selectedSZN,
                    memeName: selectedMemeName,
                    supply: selectedSupply,
                    searchTerm: searchTerm,
                });
                console.log('API Response:', response.data);
                setPrices(response.data);
                setTotalRecords(response.totalCount);

                if (response.data.length > 0) {
                    const latestTime = new Date(Math.max(...response.data.map(item => new Date(item.Timestamp))));

                    const ukTime = latestTime.toLocaleString('en-GB', {
                        timeZone: 'Europe/London',
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    });
                    const utcTime = latestTime.toUTCString().split(' ').slice(1, 5).join(' ');

                    setLatestTimestamp(`UK Time: ${ukTime}, UTC: ${utcTime}`);
                }
            } catch (error) {
                console.error('Error fetching prices:', error);
            }
        };

        const getSznPrices = async () => {
            try {
                const response = await fetchSznPrices();
                console.log('SZN Prices API Response:', response.data);
                setSznPrices(response.data);
            } catch (error) {
                console.error('Error fetching SZN prices:', error);
            }
        };

        getPrices();
        getSznPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentPage,
        sortConfig,
        selectedSZN,
        selectedMemeName,
        selectedSupply,
        searchTerm,
    ]);

    const sortTable = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
        setCurrentPage(0); // Reset to first page when sorting changes
    };

    // Handle page change
    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const suggestions = useMemo(() => {
        if (searchTerm.length < 2) return [];
        const uniqueNames = new Set(prices.map(item => item.Name));
        return Array.from(uniqueNames).filter(name =>
            name.toLowerCase().includes(searchTerm.toLowerCase())
        ).slice(0, 5);
    }, [prices, searchTerm]);

    const calculateTotalSznValues = () => {
        const totalAsk = sznPrices.reduce((sum, szn) => sum + (parseFloat(szn.AskTotal) || 0), 0);
        const totalBid = sznPrices.reduce((sum, szn) => {
            const bidTotal = parseFloat(szn.BidTotal);
            return isNaN(bidTotal) ? sum : sum + bidTotal;
        }, 0);
        const totalMarketCapAsk = sznPrices.reduce((sum, szn) => sum + (parseFloat(szn.MarketCapAsk) || 0), 0);
        const totalMarketCapBid = sznPrices.reduce((sum, szn) => sum + (parseFloat(szn.MarketCapBid) || 0), 0);
        return { totalAsk, totalBid, totalMarketCapAsk, totalMarketCapBid };
    };

    // Unique values for filters
    const [uniqueSZNs, setUniqueSZNs] = useState(['All']);
    const [uniqueMemeNames, setUniqueMemeNames] = useState(['All']);
    const [uniqueSupplies, setUniqueSupplies] = useState(['All']);

    useEffect(() => {
        // Fetch unique SZNs, MemeNames, and Supplies
        const fetchUniqueValues = async () => {
            try {
                const response = await fetchPrices({
                    page: 1,
                    pageSize: 1000, // Fetch all records to get unique values
                });
                const data = response.data;
                const szns = new Set(data.map(item => item.TypeSeason));
                setUniqueSZNs(['All', ...Array.from(szns)]);
                const memeNames = new Set(data.map(item => item.MemeName));
                setUniqueMemeNames(['All', ...Array.from(memeNames)]);
                const supplies = new Set(data.map(item => item.Supply));
                setUniqueSupplies(['All', ...Array.from(supplies).sort((a, b) => a - b)]);
            } catch (error) {
                console.error('Error fetching unique values:', error);
            }
        };
        fetchUniqueValues();
    }, []);

    return (
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
            <h1 className="main-header">Unofficial 6529 Stats</h1>
            <h2 className="sub-header">6529 Memes NFT Collection Stats</h2>
            <div style={{ textAlign: 'left', marginLeft: '20px' }}>
                <strong>Last Updated: </strong> {latestTimestamp}
            </div>

            <div style={{ marginBottom: '20px' }}></div>

            {/* SZN Valuations Table */}
            <div style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '20px' }}>
                <table className="styled-table centered-table">
                    <thead>
                        <tr>
                            <th colSpan="5" className="table-title">SZN Valuations</th>
                        </tr>
                        <tr>
                            <th>SZN ID</th>
                            <th>Ask Total</th>
                            <th>Bid Total</th>
                            <th>Market Cap (Ask)</th>
                            <th>Market Cap (Bid)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sznPrices.map((szn, index) => (
                            <tr key={index}>
                                <td>{szn.SZN_ID}</td>
                                <td>{szn.AskTotal}</td>
                                <td>{isNaN(parseFloat(szn.BidTotal)) ? '' : szn.BidTotal}</td>
                                <td>{szn.MarketCapAsk}</td>
                                <td>{szn.MarketCapBid}</td>
                            </tr>
                        ))}
                        <tr style={{ fontWeight: 'bold' }}>
                            <td>All SZNs</td>
                            <td>{calculateTotalSznValues().totalAsk.toFixed(2)}</td>
                            <td>{calculateTotalSznValues().totalBid > 0 ? calculateTotalSznValues().totalBid.toFixed(2) : ''}</td>
                            <td>{calculateTotalSznValues().totalMarketCapAsk.toFixed(2)}</td>
                            <td>{calculateTotalSznValues().totalMarketCapBid.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style={{ marginBottom: '40px' }}></div>

            {/* Filters */}
            <div className="filter-container">
                <div>
                    <label htmlFor="szn-filter">SZN: </label>
                    <select
                        id="szn-filter"
                        value={selectedSZN}
                        onChange={(e) => {
                            setSelectedSZN(e.target.value);
                            setCurrentPage(0);
                        }}
                    >
                        {uniqueSZNs.map((szn) => (
                            <option key={szn} value={szn}>{szn}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="meme-name-filter">Meme Name: </label>
                    <select
                        id="meme-name-filter"
                        value={selectedMemeName}
                        onChange={(e) => {
                            setSelectedMemeName(e.target.value);
                            setCurrentPage(0);
                        }}
                    >
                        {uniqueMemeNames.map((memeName) => (
                            <option key={memeName} value={memeName}>{memeName}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="supply-filter">Supply: </label>
                    <select
                        id="supply-filter"
                        value={selectedSupply}
                        onChange={(e) => {
                            setSelectedSupply(e.target.value);
                            setCurrentPage(0);
                        }}
                    >
                        {uniqueSupplies.map((supply) => (
                            <option key={supply} value={supply}>{supply}</option>
                        ))}
                    </select>
                </div>
                <div style={{ position: 'relative' }}>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setShowSuggestions(true);
                            setCurrentPage(0);
                        }}
                        onFocus={() => setShowSuggestions(true)}
                        onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                        placeholder="Search cards..."
                        style={{
                            width: '200px',
                            height: '30px',
                            boxSizing: 'border-box'
                        }}
                    />
                    {showSuggestions && suggestions.length > 0 && (
                        <ul className="suggestions-list">
                            {suggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    onMouseDown={() => {
                                        setSearchTerm(suggestion);
                                        setShowSuggestions(false);
                                        setCurrentPage(0);
                                    }}
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>

            {/* Table */}
            <table className="centered-table">
                <thead>
                    <tr>
                        <th onClick={() => sortTable('ID')}>Token No <ArrowUpDown className="inline" /></th>
                        <th onClick={() => sortTable('Name')}>Name <ArrowUpDown className="inline" /></th>
                        <th onClick={() => sortTable('MemeName')}>Meme Name <ArrowUpDown className="inline" /></th>
                        <th onClick={() => sortTable('Supply')}>Supply <ArrowUpDown className="inline" /></th>
                        <th onClick={() => sortTable('TypeSeason')}>SZN <ArrowUpDown className="inline" /></th>
                        <th onClick={() => sortTable('FloorPrice')}>Ask Price <ArrowUpDown className="inline" /></th>
                        <th onClick={() => sortTable('MarketCapAsk')}>Market Cap (Ask Prices) <ArrowUpDown className="inline" /></th>
                        <th onClick={() => sortTable('HighestBid')}>Top Bid <ArrowUpDown className="inline" /></th>
                        <th onClick={() => sortTable('MarketCapBid')}>Market Cap (Bid Prices) <ArrowUpDown className="inline" /></th>
                        {/* New Column Header for Last Sale Price */}
                        <th onClick={() => sortTable('LastSalePrice')}>Last Sale Price <ArrowUpDown className="inline" /></th>
                        <th onClick={() => sortTable('HodlRate')}>TDH Rate <ArrowUpDown className="inline" /></th>
                        <th onClick={() => sortTable('PPTDH')}>PPTDH <ArrowUpDown className="inline" /></th>
                        <th onClick={() => sortTable('Timestamp')}>Last Updated <ArrowUpDown className="inline" /></th>
                    </tr>
                </thead>
                <tbody>
                    {prices.map((price, index) => (
                        <tr key={index}>
                            <td>{price.ID}</td>
                            <td>{price.Name}</td>
                            <td>{price.MemeName}</td>
                            <td>{price.Supply}</td>
                            <td>{price.TypeSeason}</td>
                            <td>{price.FloorPrice}</td>
                            <td>{price.MarketCapAsk}</td>
                            <td>{price.HighestBid}</td>
                            <td>{price.MarketCapBid}</td>
                            {/* New Column Data for Last Sale Price */}
                            <td>{parseFloat(price.LastSalePrice).toFixed(5)}</td>
                            <td>{price.HodlRate}</td>
                            <td>{price.PPTDH}</td>
                            <td>{new Date(price.Timestamp).toLocaleString('en-GB', {
                                timeZone: 'Europe/London',
                                day: '2-digit',
                                month: 'long',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                            })}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination */}
            <div className="pagination">
                <ReactPaginate
                    previousLabel={'← Previous'}
                    nextLabel={'Next →'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(totalRecords / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    );
};

export default PricesComponent;
