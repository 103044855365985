// MyPortfolioComponent.js

import React, { useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import { ArrowUpDown } from 'lucide-react';
import { CSVLink } from 'react-csv'; // Import CSVLink

const API_BASE_URL = 'https://stats6529backend-f135373a86cd.herokuapp.com';

const MyPortfolioComponent = () => {
  // State variables
  const [profile, setProfile] = useState('');
  const [portfolioData, setPortfolioData] = useState([]);
  const [collectionValuation, setCollectionValuation] = useState({ bid: 0, ask: 0, lastSale: 0 });
  const [sznValuations, setSznValuations] = useState([]);
  const [sznCostToCompleteFloor, setSznCostToCompleteFloor] = useState([]);
  const [completeCollectionCostToCompleteFloor, setCompleteCollectionCostToCompleteFloor] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [lastUpdated, setLastUpdated] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedSZN, setSelectedSZN] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [ownershipFilter, setOwnershipFilter] = useState('all');

  /**
   * Handles form submission to fetch portfolio data.
   * Dynamically extracts SZN valuations and cost to complete floor from the API response.
   */
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${API_BASE_URL}/api/portfolio_all/${profile}`);
      setPortfolioData(response.data.data);
setCollectionValuation({
    bid: response.data.collectionValuationBid,
    ask: response.data.collectionValuationAsk,
    lastSale: response.data.collectionValuationLastSale
});

      // Extract SZN Valuations and Costs dynamically
      const sznData = [];
      const sznCostData = [];

      // Iterate through the response data to find all SZN Valuation and Cost attributes
      for (const key in response.data) {
        if (key.startsWith('SZN') && key.endsWith('ValuationBid')) {
            const sznNumber = parseInt(key.match(/SZN(\d+)ValuationBid/)[1], 10);
            sznData.push({
                szn: sznNumber,
                bid: response.data[`SZN${sznNumber}ValuationBid`] || 0,
                ask: response.data[`SZN${sznNumber}ValuationAsk`] || 0,
                lastSale: response.data[`SZN${sznNumber}ValuationLastSale`] || 0
            });
        }
        if (key.startsWith('SZN') && key.endsWith('CostToCompleteFloor')) {
          const sznNumber = parseInt(key.match(/SZN(\d+)CostToCompleteFloor/)[1], 10);
          sznCostData.push({
            szn: sznNumber,
            costToComplete: response.data[`SZN${sznNumber}CostToCompleteFloor`] || 0
          });
        }
      }

      // Sort the SZN data by season number for orderly display
      sznData.sort((a, b) => a.szn - b.szn);
      sznCostData.sort((a, b) => a.szn - b.szn);

      setSznValuations(sznData);
      setSznCostToCompleteFloor(sznCostData);
      setCompleteCollectionCostToCompleteFloor(response.data.CompleteCollectionCostToCompleteFloor || 0);
      setLastUpdated(new Date(response.data.lastUpdated));
    } catch (err) {
      setError('Error fetching portfolio data. Please try again.');
      console.error('Error details:', err.response ? err.response.data : err.message);
    }
    setLoading(false);
  }, [profile]);

  /**
   * Formats numbers to a fixed number of decimal places with thousand separators.
   */
  const formatNumber = (num) => {
    return num.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 });
  };

  /**
   * Formats the date to display both UK and UTC times.
   */
  const formatDate = (date) => {
    const ukTime = date.toLocaleString('en-GB', {
      timeZone: 'Europe/London',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    const utcTime = date.toLocaleString('en-GB', {
      timeZone: 'UTC',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return `UK Time: ${ukTime}, UTC: ${utcTime}`;
  };

  /**
   * Handles sorting of table columns.
   */
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  /**
   * Memoized sorted data based on sort configuration.
   */
  const sortedData = useMemo(() => {
    let sortableItems = [...portfolioData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [portfolioData, sortConfig]);

  /**
   * Memoized filtered data based on selected filters and search term.
   */
  const filteredData = useMemo(() => {
    return sortedData.filter(item => 
      (selectedSZN === 'All' || item.TypeSeason === selectedSZN) &&
      (searchTerm === '' || item.Name.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (ownershipFilter === 'all' || 
       (ownershipFilter === 'owned' && item.SeizedCount > 0) ||
       (ownershipFilter === 'notowned' && item.SeizedCount === 0))
    );
  }, [sortedData, selectedSZN, searchTerm, ownershipFilter]);

  /**
   * Extracts unique SZNs from the portfolio data for filter options.
   */
  const uniqueSZNs = useMemo(() => {
    const szns = new Set(portfolioData.map(item => item.TypeSeason));
    return ['All', ...Array.from(szns).sort((a, b) => a - b)];
  }, [portfolioData]);

  /**
   * Provides search suggestions based on the current search term.
   */
  const suggestions = useMemo(() => {
    if (searchTerm.length < 2) return [];
    const uniqueNames = new Set(portfolioData.map(item => item.Name));
    return Array.from(uniqueNames).filter(name => 
      name.toLowerCase().includes(searchTerm.toLowerCase())
    ).slice(0, 5);
  }, [portfolioData, searchTerm]);

  /**
   * Prepare CSV data for "My Portfolio" table
   */
  const portfolioCSVData = useMemo(() => {
    const headers = [
      { label: "Season", key: "Season" },
      { label: "Valuation (Bid Prices)", key: "ValuationBid" },
      { label: "Valuation (Ask Prices)", key: "ValuationAsk" },
      { label: "Valuation (Last Sale)", key: "ValuationLastSale" },
      { label: "Cost to Complete (Ask Prices)", key: "CostToComplete" },
    ];
  
    const data = sznValuations.map(szn => {
      const costData = sznCostToCompleteFloor.find(item => item.szn === szn.szn);
      return {
        Season: `SZN ${szn.szn}`,
        ValuationBid: szn.bid,
        ValuationAsk: szn.ask,
        ValuationLastSale: szn.lastSale,
        CostToComplete: costData ? costData.costToComplete : 0,
      };
    });
  
    // Add the total row
    data.push({
      Season: "Complete Collection",
      ValuationBid: collectionValuation.bid,
      ValuationAsk: collectionValuation.ask,
      ValuationLastSale: collectionValuation.lastSale,
      CostToComplete: completeCollectionCostToCompleteFloor,
    });
  
    return {
      headers,
      data,
    };
  }, [sznValuations, sznCostToCompleteFloor, collectionValuation, completeCollectionCostToCompleteFloor]);

  /**
   * Prepare CSV data for "My Portfolio Details" table
   */
  const portfolioDetailsCSVData = useMemo(() => {
    const headers = [
        { label: "Token No", key: "ID" },
        { label: "Card Name", key: "Name" },
        { label: "SZN", key: "TypeSeason" },
        { label: "Highest Bid", key: "HighestBid" },
        { label: "Floor Price", key: "FloorPrice" },
        { label: "Last Sale", key: "LastSalePrice" }, // New Header
        { label: "Seized Count", key: "SeizedCount" },
        { label: "Bid Valuation", key: "BidValuation" },
        { label: "Ask Valuation", key: "AskValuation" },
    ];
    const data = filteredData.map(item => ({
        ...item,
    }));
    return {
        headers,
        data,
    };
}, [filteredData]);

  /**
   * Styles for centered headers and explanation boxes.
   */
  const centeredHeaderStyle = {
    textAlign: 'center',
    width: '100%',
    marginTop: '30px',
    marginBottom: '20px'
  };

  const explanationBoxStyle = {
    border: '2px solid #ccc',
    borderRadius: '8px',
    padding: '15px',
    margin: '20px auto',
    backgroundColor: '#f9f9f9',
    maxWidth: '100%',
    boxSizing: 'border-box'
  };

  return (
    <div className="table-container">
      {/* Header and Form */}
      <h1 className="page-header">My 6529 Memes Portfolio</h1>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '10px' }}>
          <input
            type="text"
            value={profile}
            onChange={(e) => setProfile(e.target.value)}
            placeholder="Enter your Seize profile"
            style={{ marginRight: '10px' }}
            required
          />
          <button type="submit">
            Fetch Seize Portfolio
          </button>
        </form>
        <p style={{ fontWeight: 'bold', textAlign: 'center', width: '100%', marginBottom: '40px' }}>
          Enter your Seize profile or any wallet address within your consolidation to receive your current Bid & Ask price valuations for your 6529 Memes portfolio
        </p>
      </div>

      {/* Loading and Error Messages */}
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      {/* Portfolio Valuation Table */}
      {portfolioData.length > 0 && (
        <>
          <h2 className="sub-header" style={centeredHeaderStyle}>My Portfolio</h2>

          {/* Download Button for Portfolio Table */}
          <div className="download-button" style={{ textAlign: 'right', marginBottom: '10px' }}>
            <CSVLink
              data={portfolioCSVData.data}
              headers={portfolioCSVData.headers}
              filename={`my_portfolio_${profile}.csv`}
              className="btn btn-primary"
            >
              Download CSV
            </CSVLink>
          </div>

          <div className="complete-portfolio-data">
            <table className="styled-table centered-table">
              <thead>
                <tr>
                  <th>Season</th>
                  <th>Valuation (Bid Prices)</th>
                  <th>Valuation (Ask Prices)</th>
                  <th>Valuation (Last Sale)</th>
                  <th>Cost to Complete (Ask Prices)</th>
                </tr>
              </thead>
              <tbody>
  {sznValuations.map((szn) => {
    const costData = sznCostToCompleteFloor.find(item => item.szn === szn.szn);
    return (
      <tr key={szn.szn}>
        <td>SZN {szn.szn}</td>
        <td>{formatNumber(szn.bid)}</td>
        <td>{formatNumber(szn.ask)}</td>
        <td>{formatNumber(szn.lastSale)}</td>
        <td>{formatNumber(costData ? costData.costToComplete : 0)}</td>
      </tr>
    );
  })}
  <tr style={{ backgroundColor: '#e6f7ff', fontWeight: 'bold' }}>
    <td>Complete Collection</td>
    <td>{formatNumber(collectionValuation.bid)}</td>
    <td>{formatNumber(collectionValuation.ask)}</td>
    <td>{formatNumber(collectionValuation.lastSale)}</td>
    <td>{formatNumber(completeCollectionCostToCompleteFloor)}</td>
  </tr>
</tbody>
            </table>
          </div>

          {/* Explanation Box */}
          <div style={explanationBoxStyle}>
            <p><strong>These valuations are calculated using:</strong></p>
            <ul>
              <li>Highest bid price per token</li>
              <li>Lowest ask price (floor price) per token</li>
            </ul>
            <p><strong>Note:</strong> This method doesn't account for market depth. If you own multiple tokens of the same card, the actual value may differ from these estimates.</p>
            {lastUpdated && (
              <p><strong>Prices Last Updated at:</strong> {formatDate(lastUpdated)}</p>
            )}
          </div>

          <br /><br />

          {/* Portfolio Details Table */}
          <h2 className="sub-header" style={centeredHeaderStyle}>My Portfolio Details</h2>

          {/* Filters and Search */}
          <div style={{ 
            marginBottom: '10px', 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            {/* SZN Filter */}
            <div style={{ marginBottom: '10px', flex: '1' }}>
              <label htmlFor="szn-filter">Filter by SZN: </label>
              <select
                id="szn-filter"
                value={selectedSZN}
                onChange={(e) => setSelectedSZN(e.target.value)}
              >
                {uniqueSZNs.map((szn) => (
                  <option key={szn} value={szn}>
                    {szn}
                  </option>
                ))}
              </select>
            </div>

            {/* Ownership Filter */}
            <div style={{ marginBottom: '10px', flex: '1', textAlign: 'center' }}>
              <label htmlFor="ownership-filter">Filter by Ownership: </label>
              <select
                id="ownership-filter"
                value={ownershipFilter}
                onChange={(e) => setOwnershipFilter(e.target.value)}
              >
                <option value="all">All Tokens</option>
                <option value="owned">Owned Tokens</option>
                <option value="notowned">Tokens NOT Owned</option>
              </select>
            </div>

            {/* Search Bar */}
            <div style={{ 
              position: 'relative',
              marginLeft: 'auto',
              flex: '1',
              textAlign: 'right'
            }}>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setShowSuggestions(true);
                }}
                onFocus={() => setShowSuggestions(true)}
                onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                placeholder="Search cards..."
                style={{ 
                  width: '200px',
                  height: '30px',
                  boxSizing: 'border-box'
                }}
              />
              {showSuggestions && suggestions.length > 0 && (
                <ul style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  right: 0,
                  backgroundColor: 'white',
                  border: '1px solid #ddd',
                  borderTop: 'none',
                  zIndex: 1000,
                  listStyle: 'none',
                  padding: 0,
                  margin: 0,
                  maxHeight: '200px',
                  overflowY: 'auto'
                }}>
                  {suggestions.map((suggestion, index) => (
                    <li 
                      key={index}
                      style={{ padding: '8px', cursor: 'pointer', borderBottom: '1px solid #eee' }}
                      onMouseDown={() => {
                        setSearchTerm(suggestion);
                        setShowSuggestions(false);
                      }}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Download Button for Portfolio Details Table */}
          <div className="download-button" style={{ textAlign: 'right', marginBottom: '10px' }}>
            <CSVLink
              data={portfolioDetailsCSVData.data}
              headers={portfolioDetailsCSVData.headers}
              filename={`my_portfolio_details_${profile}.csv`}
              className="btn btn-primary"
            >
              Download CSV
            </CSVLink>
          </div>

          {/* Portfolio Details Table */}
          <table className="styled-table centered-table">
          <thead>
  <tr>
    <th onClick={() => handleSort('ID')}>
      Token No <ArrowUpDown className="inline" />
    </th>
    <th>Card Name</th>
    <th>SZN</th>
    <th onClick={() => handleSort('HighestBid')}>
      Highest Bid <ArrowUpDown className="inline" />
    </th>
    <th onClick={() => handleSort('FloorPrice')}>
      Floor Price <ArrowUpDown className="inline" />
    </th>
    {/* New Column Header for Last Sale */}
    <th onClick={() => handleSort('LastSalePrice')}>
      Last Sale <ArrowUpDown className="inline" />
    </th>
    <th onClick={() => handleSort('SeizedCount')}>
      Seized Count <ArrowUpDown className="inline" />
    </th>
    <th onClick={() => handleSort('BidValuation')}>
      Bid Valuation <ArrowUpDown className="inline" />
    </th>
    <th onClick={() => handleSort('AskValuation')}>
      Ask Valuation <ArrowUpDown className="inline" />
    </th>
  </tr>
</thead>
<tbody>
  {filteredData.map((item) => (
    <tr key={item.ID} style={ownershipFilter === 'all' && item.SeizedCount === 0 ? { backgroundColor: '#ffcccc' } : {}}>
      <td>{item.ID}</td>
      <td>{item.Name}</td>
      <td>{item.TypeSeason}</td>
      <td>{formatNumber(item.HighestBid)}</td>
      <td>{formatNumber(item.FloorPrice)}</td>
      {/* New Column Data for Last Sale */}
      <td>{item.LastSalePrice !== null ? formatNumber(item.LastSalePrice) : 'N/A'}</td>
      <td>{item.SeizedCount}</td>
      <td>{formatNumber(item.BidValuation)}</td>
      <td>{formatNumber(item.AskValuation)}</td>
    </tr>
  ))}
</tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default MyPortfolioComponent;
