// apiService.js

const API_BASE_URL = "https://stats6529backend-f135373a86cd.herokuapp.com/api";

// Updated fetchPrices function to accept query parameters
export const fetchPrices = async (params) => {
    const queryString = new URLSearchParams(params).toString();
    const response = await fetch(`${API_BASE_URL}/prices?${queryString}`);
    if (!response.ok) {
        throw new Error("Failed to fetch prices data.");
    }
    return await response.json();
};

// Existing fetch functions
export const fetchSznPrices = async () => {
    const response = await fetch(`${API_BASE_URL}/szn_prices`);
    if (!response.ok) {
        throw new Error("Failed to fetch SZN prices data.");
    }
    return await response.json();
};

export const fetchSznPricesHistory = async () => {
    const response = await fetch(`${API_BASE_URL}/szn_prices_history`);
    if (!response.ok) {
        throw new Error("Failed to fetch SZN prices history data.");
    }
    return await response.json();
};

// Existing function to fetch sales data
export const fetchSalesData = async (timePeriod) => {
    let endpoint;
    switch (timePeriod) {
        case 'last24hours':
            endpoint = 'sales_last24hours';
            break;
        case 'last7days':
            endpoint = 'sales_last7days';
            break;
        case 'last30days':
            endpoint = 'sales_last30days';
            break;
        case '2022':
            endpoint = 'sales_2022';
            break;
        case '2023':
            endpoint = 'sales_2023';
            break;
        case '2024':
            endpoint = 'sales_2024';
            break;
        case 'all':
        default:
            endpoint = 'sales_all';
            break;
    }
    const response = await fetch(`${API_BASE_URL}/${endpoint}`);
    if (!response.ok) {
        throw new Error(`Failed to fetch sales data for ${timePeriod}.`);
    }
    return await response.json();
};

// Function to fetch sales_bot_AllTime data
export const fetchSalesBotAllTimeData = async (params) => {
    // Remove parameters with undefined, null, or 'undefined' values
    const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
            ([_, v]) => v !== undefined && v !== null && v !== 'undefined'
        )
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    const response = await fetch(`${API_BASE_URL}/sales_bot_AllTime?${queryString}`);
    if (!response.ok) {
        throw new Error('Failed to fetch sales bot data.');
    }
    const data = await response.json();
    return data;
};

// Function to fetch sales summary based on timeframe or custom date range
export const fetchSalesSummary = async ({ timeFrame, startDate, endDate }) => {
    let url = `${API_BASE_URL}/sales_summary?`;

    if (timeFrame && timeFrame !== 'custom') {
        const validTimeFrames = ['24h', '7d', '30d', 'ytd', 'all'];
        if (!validTimeFrames.includes(timeFrame)) {
            throw new Error('Invalid timeFrame parameter.');
        }
        url += `timeFrame=${timeFrame}`;
    } else if (timeFrame === 'custom') {
        if (!startDate || !endDate) {
            throw new Error('startDate and endDate are required for custom time frame.');
        }
        // Optional: Add frontend validation for date format here
        url += `timeFrame=custom&startDate=${startDate}&endDate=${endDate}`;
    } else {
        throw new Error('Invalid parameters for fetchSalesSummary.');
    }

    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`Failed to fetch sales summary for ${timeFrame}.`);
    }
    return await response.json();
};
