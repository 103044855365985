// SalesSummaryComponent.js

import React, { useEffect, useState, useMemo } from 'react';
import { fetchSalesData } from './apiService';
import './App.css';
import { ArrowUpDown } from 'lucide-react';
import { CSVLink } from 'react-csv';

const SalesSummaryComponent = () => {
    // State variables
    const [salesData, setSalesData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('all');
    const [selectedSZN, setSelectedSZN] = useState('All');
    const [selectedMemeName, setSelectedMemeName] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lastSaleTime, setLastSaleTime] = useState(null);

    // Fetch data based on selectedTimePeriod
    useEffect(() => {
        const getSalesData = async () => {
            try {
                setLoading(true);
                const response = await fetchSalesData(selectedTimePeriod);
                setSalesData(response.data);
                setLastSaleTime(response.lastSaleTime);
            } catch (error) {
                console.error('Error fetching sales data:', error);
            } finally {
                setLoading(false);
            }
        };
        getSalesData();
    }, [selectedTimePeriod]);

    // Implement sorting
    const sortTable = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedSalesData = useMemo(() => {
        let sortableItems = [...salesData];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];

                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                } else if (!isNaN(parseFloat(aValue)) && !isNaN(parseFloat(bValue))) {
                    return sortConfig.direction === 'ascending' ? parseFloat(aValue) - parseFloat(bValue) : parseFloat(bValue) - parseFloat(aValue);
                } else {
                    if (aValue < bValue) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (aValue > bValue) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                }
            });
        }
        return sortableItems;
    }, [salesData, sortConfig]);

    // Get unique SZNs and Meme Names for filters
    const uniqueSZNs = useMemo(() => {
        const szns = new Set(salesData.map(item => item.typeseason));
        return ['All', ...Array.from(szns).filter(szn => szn !== null && szn !== '')];
    }, [salesData]);

    const uniqueMemeNames = useMemo(() => {
        const memeNames = new Set(salesData.map(item => item.memename));
        return ['All', ...Array.from(memeNames).filter(name => name !== null && name !== '')];
    }, [salesData]);

    const filteredSalesData = useMemo(() => {
        return sortedSalesData.filter(item =>
            (selectedSZN === 'All' || item.typeseason === selectedSZN) &&
            (selectedMemeName === 'All' || item.memename === selectedMemeName) &&
            (searchTerm === '' || item.name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [sortedSalesData, selectedSZN, selectedMemeName, searchTerm]);

    const suggestions = useMemo(() => {
        if (searchTerm.length < 2) return [];
        const uniqueNames = new Set(salesData.map(item => item.name));
        return Array.from(uniqueNames).filter(name =>
            name.toLowerCase().includes(searchTerm.toLowerCase())
        ).slice(0, 5);
    }, [salesData, searchTerm]);

    // Time Period options
    const timePeriodOptions = [
        { value: 'last24hours', label: 'Last 24 Hours' },
        { value: 'last7days', label: 'Last 7 Days' },
        { value: 'last30days', label: 'Last 30 Days' },
        { value: '2022', label: 'Year 2022' },
        { value: '2023', label: 'Year 2023' },
        { value: '2024', label: 'Year 2024' },
        { value: 'all', label: 'All Time' },
    ];

    // Compute totals for relevant columns
    const totals = useMemo(() => {
        const totalSalesCount = filteredSalesData.reduce((sum, item) => sum + parseInt(item.sales_count), 0);
        const totalSalesEth = filteredSalesData.reduce((sum, item) => sum + parseFloat(item.sales_eth), 0);
        const totalSalesUsd = filteredSalesData.reduce((sum, item) => sum + parseFloat(item.sales_usd), 0);
        return {
            totalSalesCount,
            totalSalesEth,
            totalSalesUsd
        };
    }, [filteredSalesData]);

    // Prepare data for CSV download
    const csvData = useMemo(() => {
        const headers = [
            { label: "Token No", key: "tokenid" },
            { label: "Name", key: "name" },
            { label: "Meme Name", key: "memename" },
            { label: "Artist", key: "artist" },
            { label: "SZN", key: "typeseason" },
            { label: "Sales Count", key: "sales_count" },
            { label: "Sales ETH", key: "sales_eth" },
            { label: "Sales USD", key: "sales_usd" },
            { label: "Highest Sale ETH", key: "highest_sale_eth" },
            { label: "Highest Sale USD", key: "highest_sale_usd" },
            { label: "Lowest Sale ETH", key: "lowest_sale_eth" },
            { label: "Lowest Sale USD", key: "lowest_sale_usd" },
            { label: "Average Sale ETH", key: "average_sale_eth" },
            { label: "Average Sale USD", key: "average_sale_usd" },
            { label: "No. of Bids", key: "no_bids" },
            { label: "No. of Asks", key: "no_asks" },
            { label: "Bid Percentage", key: "bid_percentage" },
            { label: "Ask Percentage", key: "ask_percentage" },
        ];

        const data = filteredSalesData.map(item => ({
            ...item
        }));

        return {
            headers,
            data
        };
    }, [filteredSalesData]);

    // Function to format date to UTC time
    const formatDate = (date) => {
        const utcDate = date.toLocaleDateString('en-GB', {
            timeZone: 'UTC',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
        const utcTime = date.toLocaleTimeString('en-GB', {
            timeZone: 'UTC',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });

        return `UTC: ${utcDate} ${utcTime}`;
    };

    // Format the last sale time
    const formattedLastSaleTime = useMemo(() => {
        if (!lastSaleTime) return '';
        const date = new Date(lastSaleTime);
        return formatDate(date);
    }, [lastSaleTime]);

    return (
        <div className="table-container sales-summary-container">
            <h1 className="main-header text-center">Sales Summary</h1>
            <div style={{ textAlign: 'left', marginLeft: '20px', marginBottom: '20px' }}>
                <label htmlFor="time-period-select"><strong>Select Time Period:</strong> </label>
                <select
                    id="time-period-select"
                    value={selectedTimePeriod}
                    onChange={(e) => setSelectedTimePeriod(e.target.value)}
                    style={{ marginLeft: '10px' }}
                >
                    {timePeriodOptions.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>

            <div className="filter-container">
                <div>
                    <label htmlFor="szn-filter">SZN: </label>
                    <select
                        id="szn-filter"
                        value={selectedSZN}
                        onChange={(e) => setSelectedSZN(e.target.value)}
                    >
                        {uniqueSZNs.map((szn) => (
                            <option key={szn} value={szn}>{szn}</option>
                        ))}
                    </select>
                </div>

                <div>
                    <label htmlFor="meme-name-filter">Meme Name: </label>
                    <select
                        id="meme-name-filter"
                        value={selectedMemeName}
                        onChange={(e) => setSelectedMemeName(e.target.value)}
                    >
                        {uniqueMemeNames.map((memeName) => (
                            <option key={memeName} value={memeName}>{memeName}</option>
                        ))}
                    </select>
                </div>

                <div style={{
                    position: 'relative',
                    marginLeft: 'auto'
                }}>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setShowSuggestions(true);
                        }}
                        onFocus={() => setShowSuggestions(true)}
                        onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                        placeholder="Search cards..."
                        style={{
                            width: '200px',
                            height: '30px',
                            boxSizing: 'border-box'
                        }}
                    />
                    {showSuggestions && suggestions.length > 0 && (
                        <ul className="suggestions-list">
                            {suggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    onMouseDown={() => {
                                        setSearchTerm(suggestion);
                                        setShowSuggestions(false);
                                    }}
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>

            {/* Download Button, Number of Records, and Last Sale Time */}
            <div className="download-records-container">
                <div style={{ marginLeft: '20px' }}>
                    <strong>Number of Records: </strong> {filteredSalesData.length}
                </div>
                {formattedLastSaleTime && (
                    <div className="last-updated">
                        <strong>Last Sale Time: </strong> {formattedLastSaleTime}
                    </div>
                )}
                <div className="download-button">
                    <CSVLink
                        data={csvData.data}
                        headers={csvData.headers}
                        filename={`sales_summary_${selectedTimePeriod}.csv`}
                        className="btn btn-primary"
                    >
                        Download CSV
                    </CSVLink>
                </div>
            </div>

            {loading ? (
                <div className="spinner"></div>
            ) : (
                <>
                    <div className="table-wrapper">
                        <table className="centered-table full-width-table">
                            <thead>
                                <tr>
                                    <th onClick={() => sortTable('tokenid')}>Token No <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('name')}>Name <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('memename')}>Meme Name <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('artist')}>Artist <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('typeseason')}>SZN <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('sales_count')}>Sales Count <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('sales_eth')}>Sales ETH <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('sales_usd')}>Sales USD <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('highest_sale_eth')}>Highest Sale ETH <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('highest_sale_usd')}>Highest Sale USD <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('lowest_sale_eth')}>Lowest Sale ETH <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('lowest_sale_usd')}>Lowest Sale USD <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('average_sale_eth')}>Average Sale ETH <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('average_sale_usd')}>Average Sale USD <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('no_bids')}>No. of Bids <ArrowUpDown className="inline" /></th>
                                    <th onClick={() => sortTable('no_asks')}>No. of Asks <ArrowUpDown className="inline" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredSalesData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.tokenid}</td>
                                        <td>{item.name}</td>
                                        <td>{item.memename}</td>
                                        <td>{item.artist}</td>
                                        <td>{item.typeseason}</td>
                                        <td>{parseInt(item.sales_count).toLocaleString('en-US')}</td>
                                        <td>{parseFloat(item.sales_eth).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</td>
                                        <td>{'$' + parseFloat(item.sales_usd).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{parseFloat(item.highest_sale_eth).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</td>
                                        <td>{'$' + parseFloat(item.highest_sale_usd).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{parseFloat(item.lowest_sale_eth).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</td>
                                        <td>{'$' + parseFloat(item.lowest_sale_usd).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{parseFloat(item.average_sale_eth).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</td>
                                        <td>{'$' + parseFloat(item.average_sale_usd).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{`${item.no_bids} (${parseFloat(item.bid_percentage).toFixed(1)}%)`}</td>
                                        <td>{`${item.no_asks} (${parseFloat(item.ask_percentage).toFixed(1)}%)`}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="5" style={{ fontWeight: 'bold' }}>Totals</td>
                                    <td style={{ fontWeight: 'bold' }}>{totals.totalSalesCount.toLocaleString('en-US')}</td>
                                    <td style={{ fontWeight: 'bold' }}>{totals.totalSalesEth.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</td>
                                    <td style={{ fontWeight: 'bold' }}>{'$' + totals.totalSalesUsd.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td colSpan="8"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

export default SalesSummaryComponent;
